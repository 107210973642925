import React from "react"

const CookiePolicyNav = ({ tab, setTab }) => {
  return (
    <div className="privacy-policy__nav">
      <button onClick={() => setTab(1)} className={tab === 1 ? "current" : ""}>
        Co to są ciasteczka?
      </button>
      <button onClick={() => setTab(2)} className={tab === 2 ? "current" : ""}>
        Do czego używamy ciasteczek?
      </button>
      <button onClick={() => setTab(3)} className={tab === 3 ? "current" : ""}>
        Jak długo przechowywane są dane w ciasteczkach?
      </button>
      <button onClick={() => setTab(4)} className={tab === 4 ? "current" : ""}>
        Rodzaje ciasteczek używanych na stronie www.memtech.pl
      </button>
      <button onClick={() => setTab(5)} className={tab === 5 ? "current" : ""}>
        Czy używamy ciasteczek podmiotów trzecich?
      </button>
      <button onClick={() => setTab(6)} className={tab === 6 ? "current" : ""}>
        W jaki sposób mogę zmienić ustawienia dot. ciasteczek albo je usunąć?
      </button>
    </div>
  )
}

export default CookiePolicyNav
