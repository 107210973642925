import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const CookiePolicyContentTab6 = () => {
  return (
    <>
      <PageSubHeader title="W jaki sposób mogę zmienić ustawienia dot. ciasteczek albo je usunąć?" />
      <p>
        Większość przeglądarek internetowych jest początkowo ustawionych na
        automatyczne przyjmowanie ciasteczek. Możesz jednak zmienić ustawienia
        przeglądarki tak, aby ciasteczka były blokowane – w całości lub w
        jakiejś części, np. tylko od stron trzecich, albo aby każdorazowo
        otrzymywać komunikat w momencie kiedy ciasteczka są wysyłane na Twoje
        urządzenie. Pamiętaj jednak, że jeżeli zablokujesz używane przez nas
        ciasteczka, może to negatywnie wpłynąć na wygodę korzystania ze strony
        www.memtech.pl, na przykład możesz nie być w stanie odwiedzić pewnych
        obszarów strony www.memtech.pl bądź nie otrzymywać spersonalizowanych
        informacji podczas ich przeglądania. Uniemożliwisz nam także zbieranie
        anonimowych informacji nt. używania naszych stron w celu stałego
        poprawiania zawartości strony www.memtech.pl.
      </p>
    </>
  )
}

export default CookiePolicyContentTab6
