import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const CookiePolicyContentTab4 = () => {
  return (
    <>
      <PageSubHeader title="Rodzaje ciasteczek używanych na stronie www.memtech.pl" />
      <p>
        Konieczne do działania stron – Niezbędne do prawidłowego funkcjonowania
        strony www.memtech.pl, pozwalają Ci na poruszanie się po nich oraz
        używanie ich elementów. Przykładowo mogą zapamiętywać poprzednie
        czynności (np. otwarte teksty) podczas wracania na stronę w tej samej
        sesji.
      </p>
      <p>
        Poprawiające wydajność – Zbieranie informacji o tym jak odwiedzający
        korzystają ze strony www.memtech.pl poprzez dostarczanie informacji na
        temat obszarów które odwiedzają, czasu jaki na nich spędzają oraz
        problemów jakie na nich napotykają, jak np. komunikaty o błędach. To
        pozwala nam poprawiać działanie strony www.memtech.pl.
      </p>
    </>
  )
}

export default CookiePolicyContentTab4
