import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const CookiePolicyContentTab3 = () => {
  return (
    <>
      <PageSubHeader title="Jak długo przechowywane są dane w ciasteczkach?" />
      <p>
        Na stronie www.memtech.pl mogą być używane dwa rodzaje ciasteczek –
        sesyjne oraz stałe. Te pierwsze pozostają na Twoim urządzeniu jedynie
        podczas korzystania ze strony www.memtech.pl. Ciasteczka stałe pozostają
        na Twoim urządzeniu tak długo jak długo mają ustawiony czas życia lub do
        momentu kiedy je usuniesz.
      </p>
    </>
  )
}

export default CookiePolicyContentTab3
