import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const CookiePolicyContentTab2 = () => {
  return (
    <>
      <PageSubHeader title="Do czego używamy ciasteczek?" />
      <p>
        www.memtech.pl używa ciasteczek w różnych celach: by serwis działał
        szybciej i był łatwiejszy w użyciu, do zbierania anonimowych,
        zagregowanych statystyk, które pozwalają nam zrozumieć jak ludzie
        używają naszych stron i pomagają w poprawianiu ich funkcjonalności i
        zawartości. Używając ciasteczek w wyżej opisany sposób nigdy nie
        identyfikujemy tożsamości użytkowników na podstawie informacji
        przechowywanych w ciasteczkach.
      </p>
    </>
  )
}

export default CookiePolicyContentTab2
