import React from "react"

import {
  CookiePolicyContentTab1,
  CookiePolicyContentTab2,
  CookiePolicyContentTab3,
  CookiePolicyContentTab4,
  CookiePolicyContentTab5,
  CookiePolicyContentTab6,
} from "./CookiePolicyContentTabs"

const CookiePolicyContent = ({ tab }) => {
  return (
    <div className="privacy-policy__content">
      {tab === 1 && <CookiePolicyContentTab1 />}
      {tab === 2 && <CookiePolicyContentTab2 />}
      {tab === 3 && <CookiePolicyContentTab3 />}
      {tab === 4 && <CookiePolicyContentTab4 />}
      {tab === 5 && <CookiePolicyContentTab5 />}
      {tab === 6 && <CookiePolicyContentTab6 />}
    </div>
  )
}

export default CookiePolicyContent
