import "styles/pages/privacy-policy.scss"

import React, { useState } from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"

import {
  CookiePolicyNav,
  CookiePolicyContent,
} from "components/Legals/CookiePolicy"

const CookiePolicy = () => {
  const title = "Polityka cookies"
  const [tab, setTab] = useState(1)

  const policyData = {
    tab,
    setTab,
  }

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />

      <section className="privacy-policy">
        <div className="container-fluid">
          <PageHeader title={title} />
          <PageSubHeader title="Wstęp" />
          <p>
            Niniejsza Polityka dotycząca Ciasteczek odnosi się do strony
            memtech.pl Przez używanie serwisu www.memtech.pl wyrażasz zgodę na
            używanie ciasteczek zgodnie z tą Polityką Ciasteczek. Jeżeli nie
            zgadzasz się na używanie przez nas ciasteczek, powinieneś zmienić
            ustawienia swojej przeglądarki w odpowiedni sposób lub zrezygnować z
            używania strony www.memtech.pl.
          </p>

          <div className="row">
            <div className="col-md-4">
              <CookiePolicyNav {...policyData} />
            </div>
            <div className="col-md-8">
              <CookiePolicyContent {...policyData} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CookiePolicy
