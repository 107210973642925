import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const CookiePolicyContentTab5 = () => {
  return (
    <>
      <PageSubHeader title="Czy używamy ciasteczek podmiotów trzecich?" />
      <p>
        Tak, korzystając z serwisu memtech.pl możesz otrzymywać ciasteczka
        pochodzące od współpracujących z www.memtech.pl podmiotów trzecich
        takich jak np. Facebook czy Google. Więcej informacji na temat tych
        ciasteczek możesz znaleźć na stronach internetowych poszczególnych
        podmiotów trzecich.
      </p>
    </>
  )
}

export default CookiePolicyContentTab5
