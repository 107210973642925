import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const CookiePolicyContentTab1 = () => {
  return (
    <>
      <PageSubHeader title="Co to są ciasteczka?" />
      <p>
        Ciasteczka (ang. cookies) to niewielkie pliki, zapisywane i
        przechowywane na twoim komputerze, tablecie lub smartphonie podczas gdy
        odwiedzasz różne strony w internecie. Ciasteczko zazwyczaj zawiera nazwę
        strony internetowej, z której pochodzi, „długość życia” ciasteczka (to
        znaczy czas jego istnienia), oraz przypadkowo wygenerowany unikalny
        numer służący do identyfikacji przeglądarki, z jakiej następuje
        połączenie ze stroną internetową.
      </p>
    </>
  )
}

export default CookiePolicyContentTab1
